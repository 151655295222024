/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import {
  CircularProgress,
  InputAdornment,
  Snackbar,
  Alert,
  TextField,
  Tooltip,
} from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../hooks/useWindowSize';
import { Container } from './styles';
import NewChat from '../NewChat';
import CloseAllTicketsButton from '../CloseAllTicketsButton';
import CustomAlert from '../CustomAlert';
import SidebarChat from '../SidebarChat';
import SidebarSections from '../SidebarSections';
import PopOverMenu from '../PopOverMenu';
import UserMenu from '../UserMenu';

import {
  baseUrl,
  getRequest,
  patchRequest,
} from '../../utils/services';

import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import EditProfile from '../EditProfile';

export default function Sidebar({ profileImageURL = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBw0DncgKmYXYHN1DqEOnRqwMW7wNO9g3Chg&s' }) {
  const [chatPage, setChatPage] = useState(1);
  const [searchChats, setSearchChats] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [isFetchingChats, setIsFetchingChats] = useState(false);

  const { user } = useContext(AuthContext);

  const { width } = useWindowSize();

  const isNotDesktop = width <= 834;

  const {
    userChats,
    showNewChat,
    setShowNewChat,
    initialParamsForNewChat,
    isUserChatsLoading,
    setIsUserChatsLoading,
    setCurrentChat,
    sortedChats,
    setSortedChats,
    userChatsQueue,
    getUserChatsQueue,
    openTickets,
    getOpenTickets,
    isTicketTransferred,
    setIsTicketTransferred,
    activeSectionChats,
    setActiveSectionChats,
    handleCreateTicketClosedNotification,
    currentChat,
    getUserChats,
    isMessagesLoading,
    isEditingProfile,
    isEditingProfileChat,
    sortUserChatsByRecentMessages,
    alert,
    hideAlert,
    showAlert,
  } = useContext(ChatContext);

  const containerRef = useRef();

  const { height } = useWindowSize();

  const scrollToTop = () => {
    const container = containerRef.current;
    if (container) {
      containerRef.current.scrollTo({ top: 0 });
    }
  };

  const handleClosePopOver = () => {
    setShowNewChat(false);
  };

  const filterChats = async (searchQuery) => {
    try {
      setIsUserChatsLoading(true);

      const response = await getRequest(`${baseUrl}/chats/${user?.id}?page=${chatPage}&limit=15&searchQuery=${encodeURIComponent(searchQuery)}`);

      setIsUserChatsLoading(false);

      return response;
    } catch (error) {
      console.error('Error fetching chats from database: ', error);
      return [];
    }
  };

  const handleCloseAllTicketsInChats = async () => {
    const userId = user.id;

    openTickets.map((ticket) => {
      handleCreateTicketClosedNotification(
        ticket.sender,
        ticket.fromMsg,
        user.ambiente,
      );
    });

    const response = await patchRequest(`${baseUrl}/chats/closeAllTicketsInChats`, JSON.stringify({ userId }));

    if (response.error) {
      return console.error('Erro ao fechar todos os chamados: ', response.error);
    }

    showAlert('Chamados encerrados com sucesso', 'success', 5000);

    getOpenTickets();
  };

  const handleCloseAllTicketsInQueue = async () => {
    const userId = user.id;

    userChatsQueue.map((chatQueue) => {
      handleCreateTicketClosedNotification(
        chatQueue.sender,
        chatQueue.fromMsg,
        user.ambiente,
      );
    });

    const response = await patchRequest(`${baseUrl}/chats/closeAllTicketsInQueue`, JSON.stringify({ userId }));

    if (response.error) {
      return console.error('Erro ao fechar todos os chamados: ', response.error);
    }

    showAlert('Chamados encerrados com sucesso', 'success', 5000);
    getUserChatsQueue();
  };

  function debounce(func, wait) {
    let timeout;
    const debouncedFunction = (...args) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
    debouncedFunction.cancel = () => clearTimeout(timeout);
    return debouncedFunction;
  }

  const DEBOUNCE_DELAY = 300;

  const handleSearch = async () => {
    if (searchChats !== ''
      && activeSectionChats === 'contatos'
    ) {
      scrollToTop();
      setChatPage(1);
      const results = await filterChats(searchChats);
      return setFilteredChats(results);
    }
    return setFilteredChats(userChats);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, DEBOUNCE_DELAY), [
    searchChats,
    activeSectionChats,
    userChats,
  ]);

  useEffect(() => {
    debouncedHandleSearch();

    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchChats,
    activeSectionChats,
    userChats,
    debouncedHandleSearch]);

  useEffect(() => {
    const sorted = sortUserChatsByRecentMessages(filteredChats);
    setSortedChats(sorted);
  }, [
    filteredChats,
  ]);

  const fetchMoreChats = async () => {
    if (isFetchingChats) return;
    setIsFetchingChats(true);
    const response = await getUserChats(chatPage + 1);
    setChatPage(chatPage + 1);

    if (response.length < 15) {
      setHasMoreChats(false);
    }
    setIsFetchingChats(false);
  };

  const handleSectionClick = async (section) => {
    setActiveSectionChats(section);
  };

  const handleInputSearchChange = (event) => {
    setSearchChats(event.target.value);
  };

  const handleCloseSnackbar = (e, reason) => {
    if (reason === 'clickaway') return;

    setIsTicketTransferred(false);
  };

  if (isEditingProfile) return <EditProfile />;

  return ((!isNotDesktop && currentChat) || !currentChat) && (
    <Container>
      <header>
        <img
          src={profileImageURL}
          alt="Foto de perfil do usuário"
        />
        <h1>{user.name}</h1>
        <div>

          <PopOverMenu
            tooltipTitle="Iniciar nova conversa"
            icon={(
              <ChatIcon
                style={{ color: '#919191' }}
              />
            )}
            openPopover={showNewChat}
            onClosePopover={handleClosePopOver}
          >
            <NewChat initialParams={initialParamsForNewChat} />
          </PopOverMenu>

          <PopOverMenu
            tooltipTitle="Opções do usuário"
            icon={(
              <MoreVertIcon
                style={{ color: '#919191' }}
              />
)}
          >
            <UserMenu />
          </PopOverMenu>

        </div>
      </header>
      <TextField
        placeholder="Procure uma conversa"
        variant="outlined"
        value={searchChats}
        sx={{
          '& fieldset': { border: 'none' },
          backgroundColor: '#F5F5F5',
          borderRadius: '10px',
          width: '95%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignSelf: 'center',
          margin: '10px 0 10px 10px',
        }}
        InputProps={{
          onChange: handleInputSearchChange,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="medium" />
            </InputAdornment>
          ),
        }}
      />

      <SidebarSections
        activeSection={activeSectionChats}
        onSectionClick={handleSectionClick}
        openedTickets={openTickets?.length}
        userChatQueue={userChatsQueue?.length}
      />

      <div style={{
        alignSelf: 'center',
      }}
      />
      <main
        id="scrollable-main"
        ref={containerRef}
      >

        {activeSectionChats === 'chats' && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        >

          {openTickets?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Não existem chamados em aberto
                  </h3>
                )
            )
            : (
              <div>
                {openTickets?.filter((chat) => chat
                  .fromMsg
                  .toLowerCase()
                  .includes(searchChats
                    .toLowerCase()))
                  .map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if ((currentChat?.fromMsg === chat.fromMsg
                          && currentChat?.sender === chat.sender)
                        || isMessagesLoading
                        ) {
                          return;
                        }
                        setCurrentChat(chat);
                      }}
                    >
                      <SidebarChat
                        imageURL={openTickets ? openTickets.imageUrl : null}
                        chat={chat}
                        loggedUser={user}
                        isChatActive={
                        currentChat?.fromMsg === chat.fromMsg
                        && currentChat?.sender === chat.sender
                        }
                      />
                    </div>
                  ))}
                <div
                  style={{
                    marginTop: 'auto',
                    padding: '10px',
                    position: 'absolute',
                    right: '10%',
                    bottom: height < 768 ? '14%' : '2%',
                  }}
                >
                  <CloseAllTicketsButton
                    icon={<PhoneDisabledIcon />}
                    tooltip="Encerrar todos chamados"
                    closeAllTicketsFunc={handleCloseAllTicketsInChats}
                  />
                </div>
              </div>
            )}
        </div>
        )}

        <Snackbar
          open={isTicketTransferred}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Chamado transferido com sucesso
          </Alert>
        </Snackbar>

        <CustomAlert alert={alert} hideAlert={hideAlert} />

        {activeSectionChats === 'fila' && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        >

          {userChatsQueue?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Não existem chamados na fila
                  </h3>
                )
            )
            : (
              <div>
                {userChatsQueue?.filter(
                  (chat) => chat.fromMsg.toLowerCase().includes(searchChats.toLowerCase()),
                )
                  .map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if ((currentChat?.fromMsg === chat.fromMsg
                          && currentChat?.sender === chat.sender)
                        || isMessagesLoading) {
                          return;
                        }
                        setCurrentChat(chat);
                      }}
                    >
                      <SidebarChat
                        imageURL={
                    userChatsQueue
                      ? userChatsQueue.imageUrl
                      : null
                  }
                        chat={chat}
                        loggedUser={user}
                        isChatActive={
                    currentChat?.fromMsg === chat.fromMsg
                    && currentChat?.sender === chat.sender
                  }
                      />
                    </div>
                  ))}
                <div style={{
                  marginTop: 'auto',
                  padding: '10px',
                  position: 'absolute',
                  right: '10%',
                  bottom: height < 768 ? '14%' : '2%',
                }}
                >

                  <CloseAllTicketsButton
                    icon={<PhoneDisabledIcon />}
                    tooltip="Encerrar todos chamados"
                    closeAllTicketsFunc={handleCloseAllTicketsInQueue}
                  />

                </div>
              </div>
            )}
        </div>
        )}

        {activeSectionChats === 'contatos' && (
        <InfiniteScroll
          dataLength={sortedChats?.length}
          next={fetchMoreChats}
          hasMore={hasMoreChats}
          scrollableTarget="scrollable-main"
          loader={(
            <div style={{
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <CircularProgress variant="indeterminate" />
            </div>
)}
        >

          {sortedChats?.length < 1
            ? (
              isUserChatsLoading ? (
                <div
                  style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                  />
                </div>
              )
                : (
                  <h3
                    style={{
                      color: '#919191',
                      fontWeight: '600',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Nenhum resultado encontrado
                  </h3>
                )
            )
            : (
              sortedChats?.map((chat, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if ((currentChat?.fromMsg === chat.fromMsg
                      && currentChat?.sender === chat.sender)
                    || isMessagesLoading) {
                      return;
                    }
                    setCurrentChat(chat);
                  }}
                >
                  <SidebarChat
                    imageURL={
                userChats
                  ? userChats.imageUrl
                  : null
}
                    chat={chat}
                    loggedUser={user}
                    isChatActive={
                      currentChat?.fromMsg === chat.fromMsg
                        && currentChat?.sender === chat.sender
                    }
                  />
                </div>
              ))

            )}
        </InfiniteScroll>
        )}

      </main>
    </Container>
  );
}

Sidebar.propTypes = {
  profileImageURL: PropTypes.string,
};
