import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Tooltip } from '@mui/material';
import { Container } from './styles';
import { baseUrl, formatPhoneNumber, patchRequest } from '../../utils/services';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function EditProfileChat({
  profileImageURL = null,
  contactName,
  contactPhone,
}) {
  const { user } = useContext(AuthContext);
  const {
    currentChat,
    setCurrentChat,
    setOpenTickets,
    setSortedChats,
    setUserChatsQueue,
    messages,
    setChatMarkedAsUnread,
    setIsEditidingProfileChat,
    showAlert,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const [isEditingContactName, setIsEditingContactName] = useState(false);
  const [newContactName, setNewContactName] = useState(currentChat?.customName || null);
  const [contactNote, setContactNote] = useState(currentChat?.obs || null);

  const handleOpenIsEditingContactName = () => {
    setIsEditingContactName(true);
  };

  const handleCloseIsEditingContactName = () => {
    setIsEditingContactName(false);
  };

  const handleOnChangeContactName = (e) => {
    const { value } = e.target;
    setNewContactName(value);
  };

  const handleOnChangeContactNote = (e) => {
    const { value } = e.target;
    setContactNote(value);
  };

  const handleCancelEditingProfileChat = () => {
    setIsEditidingProfileChat(false);
  };

  const handleChatMarkAsUnread = () => {
    if (currentChat.status === 1) {
      setUserChatsQueue((prevQueue) => prevQueue
        .map((chat) => (chat.id === currentChat.id
          ? { ...chat, unreadCount: 1 }
          : chat)));
    }

    if (currentChat.status === 2 && currentChat.usuario_id === user.id) {
      setOpenTickets((prevTickets) => prevTickets.map((chat) => (chat.id === currentChat.id
        ? { ...chat, unreadCount: 1 }
        : chat)));
    }

    setSortedChats((prevSortedChats) => prevSortedChats.map((chat) => (chat.id === currentChat.id
      ? { ...chat, unreadCount: 1 }
      : chat)));

    const lastMessageIdFromOtherUser = messages.find((message) => message.fromMe === 0);
    const lastMessageId = lastMessageIdFromOtherUser ? lastMessageIdFromOtherUser.id : null;

    const response = patchRequest(`${baseUrl}/chats/updateToUnread`, JSON.stringify({ id: lastMessageId }));

    if (response.error) {
      return setErrorMessage('Erro ao marcar conversa como não lida. Entre em contato com o suporte.');
    }
  };

  const customName = newContactName
  || currentChat?.nome
  || formatPhoneNumber(currentChat?.fromMsg);

  const obs = contactNote || currentChat.obs || 'Escreva aqui uma observação sobre esse atendimento.';

  const handleSaveChanges = async () => {
    try {
      await patchRequest(`${baseUrl}/chats/updateProfileChat`, JSON.stringify({
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        obs,
        customName,
      }));

      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        obs: contactNote !== '' || !currentChat.obs
          ? contactNote
          : currentChat.obs,
        customName: newContactName !== '' || !currentChat.customName
          ? newContactName
          : currentChat.customName,
      }));

      showAlert('Alterações salvas com sucesso', 'success', 5000);

      handleCloseIsEditingContactName();
    } catch (error) {
      setErrorMessage('Erro ao alterar dados do contato. Entre em contato com o suporte');
    }
  };

  return (
    <Container>
      <button
        type="button"
        aria-label="cancelar edição de contato"
        onClick={handleCancelEditingProfileChat}
        className="cancel-edit-contact-button"
      >
        <CloseOutlinedIcon style={{
          color: '#919191',
          fontSize: '30px',
        }}
        />
      </button>
      <div
        className="profile-img-wrapper"
      >
        <img
          src={profileImageURL || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635'}
          alt="Foto do usuário"
        />

        {
        isEditingContactName
          ? (
            <div>
              <input
                value={newContactName}
                placeholder={currentChat.customName
                  ? currentChat.customName
                  : 'Editar nome do contato'}
                onChange={handleOnChangeContactName}
              />
              <Tooltip title="Cancelar alteração">
                <button
                  type="button"
                  aria-label="Cancelar alteração no nome do contato"
                  onClick={handleCloseIsEditingContactName}
                >
                  <CloseOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
          : (
            <div>
              <h1>{contactName}</h1>
              <Tooltip title="Alterar nome do contato">
                <button
                  type="button"
                  aria-label="Alterar nome do contato"
                  onClick={handleOpenIsEditingContactName}
                >
                  <ModeEditOutlineOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
        }
        <Tooltip title="Número do contato">
          <span>
            <PhoneAndroidIcon />
            {formatPhoneNumber(contactPhone)}
          </span>
        </Tooltip>
      </div>
      <div className="not-readed-div">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="16" height="16">
          <circle cx="50" cy="50" r="50" fill="#D32F2F" />
        </svg>
        <button
          type="button"
          aria-label="Marcar chat como não lido"
          onClick={handleChatMarkAsUnread}
        >
          Marcar como não lida
        </button>
      </div>
      <div
        className="profile-fields-wrapper"
      >
        <h2>Observações</h2>
        <textarea
          value={contactNote}
          placeholder="Escreva aqui uma observação sobre esse atendimento."
          onChange={handleOnChangeContactNote}
        />
        <button
          aria-label="Salvar alterações"
          type="button"
          onClick={handleSaveChanges}
          disabled={newContactName === currentChat.customName
            && contactNote === currentChat.obs}
        >
          Salvar alterações
        </button>
      </div>

    </Container>
  );
}

EditProfileChat.propTypes = {
  profileImageURL: PropTypes.string,
  contactName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
};
