/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import {
  useContext,
  useState,
} from 'react';
import ModalImage from 'react-modal-image';
import CheckIcon from '@mui/icons-material/Check';
import he from 'he';
import {
  Dialog,
  DialogContent,
  Tooltip,
  Popover,
  Button,
} from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BlockIcon from '@mui/icons-material/Block';
import Linkify from 'linkify-react';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomDialog from '../CustomDialog';
import { deleteMessageUrl, formatPhoneNumber, postRequest } from '../../utils/services';
import { Container, ContactCard } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function ChatMessage({
  body,
  date,
  mediaType = null,
  mediaUrl = null,
  videoThumb = null,
  isReaded,
  idMsg,
  fromMe,
  isReceived,
  isDeleted,
}) {
  const { user } = useContext(AuthContext);
  const {
    currentChat,
    messages,
    setMessages,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
    handlePopoverClose();
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const token = user.senders[currentChat?.sender].sessionId;

  const handleDeleteMessage = async () => {
    console.log('antes da resposta!!');
    const response = await postRequest(
      deleteMessageUrl,
      JSON.stringify({
        token,
        fromMsg: currentChat.fromMsg,
        messageId: idMsg,
      }),
    );

    const cleanedResponse = response.split('{')[1].split('}')[0];
    const jsonResponse = JSON.parse(`{${cleanedResponse}}`);

    if (!jsonResponse || jsonResponse.error !== false) {
      setErrorMessage('Erro ao excluir mensagem. Entre em contato com o suporte');
      console.error('Error deleting message:', jsonResponse);
      return;
    }

    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((message) => {
        if (message.idMsg === idMsg) {
          console.log('Updating message:', message);
          return { ...message, isDeleted: 1 };
        }
        return message;
      });

      console.log('After update:', updatedMessages);

      return [...updatedMessages];
    });

    setIsDialogOpen(false);
  };

  const open = Boolean(anchorEl);

  const setAlignItems = () => {
    if (fromMe === 1 || fromMe === 3) return 'flex-end';
    if (fromMe === 0) return 'flex-start';
    return 'center';
  };

  const getMessageContentStyle = () => {
    if (fromMe === 2) {
      return {
        backgroundColor: '#DCE0E8',
        maxWidth: '100%',
        width: '90%',
        padding: '20px',
        textAlign: 'center',
        border: '1px solid #CFD4Df',
        boxShadow: 'none',
        margin: '20px 0',
      };
    }

    if (fromMe === 3) {
      return {
        backgroundColor: '#FFFF66',
        maxWidth: '50%',
        padding: '10px',
        textAlign: '',
        boxShadow: 'none',
      };
    }

    return {
      backgroundColor: fromMe === 1
        ? '#DCF8C6'
        : '#FFFFFF',
      maxWidth: '50%',
      padding: '10px',
    };
  };

  const getSenderName = () => {
    if (fromMe === 0) {
      return currentChat.customName
      || currentChat.nome
      || formatPhoneNumber(currentChat.fromMsg);
    }
  };

  const formatContactCardBody = (body) => {
    if (mediaType === 'contactMessage') {
      const nameMatch = body.match(/FN:([^;]*);/);
      const contactName = nameMatch ? nameMatch[1].trim() : '';
      const phoneMatches = [...body.matchAll(/waid=(\d+):/g)];

      return (
        <>
          <h1>{contactName}</h1>
          {phoneMatches.map((match, index) => (
            <span key={index}>
              {formatPhoneNumber(match[1])}
            </span>
          ))}
        </>
      );
    }
    return null;
  };

  const formatMessageBody = (message, isDeleted = null) => {
    const regex = /^\*(.*?)\*:\s*/;
    const match = message.match(regex);

    const linkifyOptions = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };

    const formatTextWithLineBreaks = (text) => {
      return text.split('\n').map((line, index) => (
        <>
          {line}
          <br key={index} />
        </>
      ));
    };

    const messageStyle = {
      textDecoration: isDeleted ? 'line-through' : 'none',
    };

    if (match) {
      const boldText = match[1];
      const remainingText = message.replace(regex, '');

      return (
        <>
          <i><strong>{`${boldText}:`}</strong></i>
          <p style={messageStyle}>
            <Linkify options={linkifyOptions}>
              {formatTextWithLineBreaks(remainingText)}
            </Linkify>
          </p>
        </>
      );
    }

    return (
      <p style={messageStyle}>
        <Linkify options={linkifyOptions}>
          {formatTextWithLineBreaks(message)}
        </Linkify>
      </p>
    );
  };

  const isAReactionMsg = mediaType === 'reactMsg';

  return !isAReactionMsg && (
    <Container style={{
      alignItems: setAlignItems(),
    }}
    >

      <div
        className="message-content-div"
        style={getMessageContentStyle()}
      >
        {fromMe === 1 && (
        <>
          <button
            aria-label="Abrir menu de mensagens"
            type="button"
            className="message-menu-button"
            onClick={handlePopoverOpen}
          >
            <MoreHorizIcon aria-hidden={false} />
          </button>

          <Popover
            style={{
              width: '200px',

            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <button
              onClick={handleDialogOpen}
              aria-label="Excluir mensagem"
              type="button"
              className="delete-message-button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 10px',
                textAlign: 'center',
                fontSize: '16px',
                width: '100%',
                fontWeight: '600',
                transition: 'all 0.2s ease',
                backgroundColor: '#fff',
              }}
              onMouseEnter={(e) => { e.target.style.filter = 'brightness(0.9)'; }}
              onMouseLeave={(e) => { e.target.style.filter = 'brightness(1)'; }}
            >
              <DeleteOutlineIcon style={{
                fontSize: '20px',
                marginRight: '5px',
              }}
              />
              {' '}
              Apagar
            </button>

          </Popover>
        </>
        )}
        <div className="header-message-div">
          <h4>{getSenderName()}</h4>
          <span>
            {
            currentChat.nome && fromMe === 0
              ? formatPhoneNumber(currentChat.fromMsg)
              : ''
            }
          </span>
        </div>

        {mediaType === 'contactMessage'
          ? (
            <ContactCard>
              {formatContactCardBody(he.decode(body))}
            </ContactCard>
          )
          : (formatMessageBody(he.decode(body), isDeleted))}

        {mediaType.startsWith('image') && (
          <div className="message-media-div">
            <ModalImage
              small={mediaUrl}
              large={mediaUrl}
              alt="Imagem enviada pelo usuário"
            />
          </div>
        )}
        {mediaType.startsWith('audio') && (
          <div style={{ maxWidth: '500px' }}>
            <audio controls>
              <source src={mediaUrl} type="audio/webm" />
              <track kind="captions" src="" />
            </audio>
          </div>
        )}
        {mediaType.startsWith('video') && (
          <div className="message-media-div">
            <button
              aria-label="Iniciar o vídeo"
              type="button"
              onClick={handleOpenVideo}
              style={{
                backgroundImage: `url(${videoThumb})`,
                backgroundSize: 'cover',
                position: 'relative',
              }}
            >
              <PlayArrow />
            </button>

            <Dialog
              open={isVideoOpen}
              onClose={handleCloseVideo}
            >
              <DialogContent
                style={{
                  padding: '0px',
                  backgroundColor: 'black',
                }}
              >
                <video
                  src={mediaUrl}
                  controls
                  style={{
                    width: '100%',
                    height: '70vh',
                  }}
                  autoPlay
                >
                  <track kind="captions" srcLang="pt" label="Portuguese captions" />
                </video>
              </DialogContent>
            </Dialog>
          </div>
        )}

        {mediaType.startsWith('application') && (
        <div
          className="message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip title="Baixar documento">
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#014F6D',
                  color: '#F5F5F5',
                  textDecoration: 'none',
                }}
                href={mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
                download
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
                Baixar documento
              </a>
            </Tooltip>
          </div>
        </div>
        )}

        {(mediaType.startsWith('text/csv') || mediaType.startsWith('text/plain')) && (
        <div
          className="message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip title="Baixar documento">
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#014F6D',
                  color: '#F5F5F5',
                  textDecoration: 'none',
                }}
                href={mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
                download
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
                Baixar documento
              </a>
            </Tooltip>
          </div>
        </div>
        )}

        {isDeleted === 1 && (
        <span
          className="message-deleted-text"
        >
          <BlockIcon
            color="#919191"
            fontSize="12px"
          />

          Mensagem excluída pelo usuário
        </span>
        )}

        { fromMe !== 2 && (
        <span className="time-and-confirmation-span">
          {date}
          <span style={{ display: 'flex' }}>
            {fromMe === 1 && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px' }} />
            )}
            {fromMe === 1 && isReceived && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px', marginLeft: '-15px' }} />
            )}
          </span>
        </span>
        )}

        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDeleteMessage}
          message="Tem certeza que deseja apagar essa mensagem"
          actionName="Apagar"
          confirmButtonColor="red"
        />
      </div>
    </Container>
  );
}

ChatMessage.propTypes = {
  body: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isReceived: PropTypes.bool.isRequired,
  isReaded: PropTypes.bool.isRequired,
  fromMe: PropTypes.number.isRequired,
  idMsg: PropTypes.string.isRequired,
  isDeleted: PropTypes.number,
  mediaUrl: PropTypes.string,
  mediaType: PropTypes.string,
  videoThumb: PropTypes.string,
};
