import {
  useContext, useState, useCallback, useRef,
} from 'react';
import {
  TextField, Button, Snackbar, Alert,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Container, LoginForm } from './styles';
import { AuthContext } from '../../context/AuthContext';
import useWindowSize from '../../hooks/useWindowSize';
import ErrorAlert from '../../components/ErrorAlert';
import EditProfile from '../../components/EditProfile';

export default function Home() {
  const {
    loginInfo,
    updateLoginInfo,
    loginUser,
    passwordChangeMessage,
    setPasswordChangeMessage,
    isLoginLoading,
    needsPasswordReset,
  } = useContext(AuthContext);

  const [captchaToken, setCaptchaToken] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { width } = useWindowSize();

  const isNotDesktop = width <= 1028;

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const captchaRef = useRef(null);

  const handleLogin = useCallback(
    (e) => {
      loginUser(e, captchaToken, captchaRef);
    },
    [loginUser, captchaToken],
  );

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Container>
      <ErrorAlert />
      {!isNotDesktop && (
        <div className="intro-modal-div">
          <header>
            <img
              className="logo-chattelein"
              src="https://chat.telein.com.br/images/login-teleinchat.png"
              alt="Logomarca dando as boas vindas ao Chat Telein"
            />
          </header>

        </div>
      )}
      <div
        className="login-form-div"
        style={{
          width: isNotDesktop ? '100%' : '50%',
        }}
      >

        {needsPasswordReset ? (
          <EditProfile />
        ) : (
          <LoginForm
            onSubmit={handleLogin}
            style={{
              width: '100%',
              maxWidth: isNotDesktop ? '450px' : '400px',
            }}
          >
            {isNotDesktop && (
              <img
                className="welcome-img"
                src="https://chat.telein.com.br/images/welcome-teleinchat.png"
                alt="Bem-vindo ao Chat Telein"
                style={{
                  width: '600px',
                  height: 'auto',
                }}
              />
            )}
            <h1>Inicie sua sessão</h1>
            <TextField
              id="email"
              label="E-mail"
              variant="outlined"
              margin="normal"
              type="email"
              size="small"
              fullWidth
              onChange={(e) => updateLoginInfo({
                ...loginInfo,
                email: e.target.value,
              })}
            />

            <div className="password-field">
              <TextField
                id="password"
                label="Senha"
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                type={passwordVisible ? 'text' : 'password'}
                onChange={(e) => updateLoginInfo({
                  ...loginInfo,
                  password: e.target.value,
                })}
                InputProps={{
                  endAdornment: (
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {passwordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </button>
                  ),
                }}
              />
            </div>

            <ReCAPTCHA
              style={{
                margin: '20px 0 40px 0',
              }}
              ref={captchaRef}
              sitekey="6Ld-2X0pAAAAALFJnnG5lTj_D7SeDpQPjAM1MGu0"
              onChange={onCaptchaChange}
            />

            <Button
              type="submit"
              className="submit-login-button"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoginLoading}
            >
              {isLoginLoading ? 'Fazendo login...' : 'Login'}
            </Button>
          </LoginForm>
        )}

        {passwordChangeMessage !== '' && (
          <Snackbar
            autoHideDuration={5000}
            onClose={() => setPasswordChangeMessage('')}
            open
          >
            <Alert
              variant="filled"
              severity={needsPasswordReset ? 'info' : 'success'}
              onClose={() => setPasswordChangeMessage('')}
            >
              {passwordChangeMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    </Container>
  );
}
