/* eslint-disable no-nested-ternary */
import {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { Tooltip } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import 'moment/locale/pt-br';

import InfiniteScroll from 'react-infinite-scroll-component';
import { postRequest, baseUrl, formatPhoneNumber } from '../../utils/services';

import ChatHeader from '../ChatHeader';
import ChatMessage from '../ChatMessage';

import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

import {
  Container,
  ContainerHeader,
  ContainerChat,
  ContainerFooter,
  ScrollDownButton,
} from './styles';

import { useFetchRecipientUser } from '../../hooks/useFetchRecipientUser';
import ChatIntro from '../ChatIntro';
import SendMedias from '../SendMedias';
import AudioRecorder from '../AudioRecorder';
import useWindowSize from '../../hooks/useWindowSize';
import { useErrors } from '../../context/ErrorContext';

export default function ChatWindow() {
  const {
    socket,
    setMessagesPage,
    hasMoreMessages,
    urlImageToSend,
    setMessageToScroll,
    currentChat,
    containerRef,
    handleScrollChatToBottom,
    isInitialMount,
    messages,
    isMessagesLoading,
    sendMessage,
    selectedImageToSend,
    setUrlImageToSend,
    urlVideoToSend,
    setUrlVideoToSend,
    selectedVideoToSend,
    setSelectedVideoToSend,
    setSelectedImageToSend,
    selectedDocumentToSend,
    urlDocumentToSend,
    setUrlDocumentToSend,
    setSelectedDocumentToSend,
    updateUserChatFromQueueToChats,
    audioBlobURL,
    isRecordingAudio,
    isUploadingAudioMessage,
    cancelAudioRecording,
    isRecipientOnline,
    setOpenTransferModal,
  } = useContext(ChatContext);

  moment.locale('pt-br');

  const [isEmojiMenuVisible, setIsEmojiMenuVisible] = useState(false);
  const [isChatFullyBottom, setIsChatFullyBottom] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [isAComment, setIsAComment] = useState(false);

  const { user } = useContext(AuthContext);

  const { recipientUser } = useFetchRecipientUser(currentChat, user);
  const { width, height } = useWindowSize();

  const { setErrorMessage } = useErrors();

  const isNotDesktop = width <= 1028;

  const prevChatRef = useRef(currentChat);

  const toggleIsAComment = () => {
    setIsAComment((prevState) => !prevState);
    setTextMessage('');
  };

  const container = containerRef.current;

  const handleScroll = () => {
    if (container) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = container;

      setIsChatFullyBottom(scrollTop + clientHeight === scrollHeight);

      if (!isMessagesLoading && scrollTop < 20 && hasMoreMessages) {
        const prevScrollHeight = container.scrollHeight / 3;
        setMessagesPage((prevPage) => prevPage + 1);
        container.scrollTop += prevScrollHeight;
      }
    }
  };

  useEffect(() => {
    if ((isInitialMount?.current
      && messages?.length > 0)
    ) {
      handleScrollChatToBottom();
      isInitialMount.current = false;
    }

    if (!currentChat || prevChatRef.current !== currentChat) {
      isInitialMount.current = true;
      prevChatRef.current = currentChat;
    }
  }, [
    handleScroll,
    messages,
    currentChat,
    prevChatRef,
    isInitialMount,
    isMessagesLoading,
    hasMoreMessages,
    setMessagesPage]);

  const textareaRef = useRef(null);
  const containerFooterRef = useRef(null);

  const handleResizeTextarea = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      const { scrollHeight } = textarea;
      const maxHeight = 150;

      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handlePaste = (event) => {
    const clipboardItem = event.clipboardData.items[0];

    if (clipboardItem && clipboardItem.type.startsWith('image/')) {
      const blob = clipboardItem.getAsFile();

      if (blob) {
        setSelectedImageToSend([blob]);
      }

      event.preventDefault();
    }
  };

  const handleDragOver = (e) => {
    const { dataTransfer } = e;

    e.preventDefault();
    dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      const file = files[0];

      if (file.type.startsWith('image/')) {
        setSelectedImageToSend(files);
      } else if (file.type.startsWith('video/')) {
        setSelectedVideoToSend(files);
      } else {
        setSelectedDocumentToSend(files);
      }
    }
  };

  const toggleEmojiMenu = () => {
    setIsEmojiMenuVisible((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setTextMessage((prevText) => prevText + emojiObject.emoji);
  };

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleChange = (event) => {
    setTextMessage(event.target.value);
  };

  const sendMediaMessages = async (textMessage, currentChat, setTextMessage, mediaUrls) => {
    if (mediaUrls.length > 0) {
      Promise.all(
        mediaUrls.map(async (url) => {
          await sendMessage(
            textMessage,
            currentChat?.fromMsg,
            setTextMessage,
            url,
          );
        }),
      );
    }
  };

  const handleSendMessage = async () => {
    if (
      (!textMessage.trim()
      || /^[\s\n]*$/.test(textMessage))
      && !urlImageToSend
      && !audioBlobURL
      && !urlVideoToSend
      && !urlDocumentToSend
    ) return;

    if (!isAComment) {
      updateUserChatFromQueueToChats(currentChat);
    }

    const textMessageWithName = `*${user.name}*:\n ${textMessage}`;

    if (isAComment) {
      const commentPayload = {
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        ambiente: user.ambiente,
        body: textMessageWithName,
        ack: 0,
        mediaType: 'text/notification',
        fromMe: 3,
        isDeleted: null,
        setorId: currentChat.setor_id,
        userId: currentChat.usuario_id,
        createdAt: Date.now(),
      };

      const response = await postRequest(`${baseUrl}/chats/insertNotification`, JSON.stringify(commentPayload));

      if (response.error) {
        return setErrorMessage('Erro ao adicionar comentário! Entre em contato com o suporte.');
      }

      socket.emit('sendComment', commentPayload, user.ambiente);

      setTextMessage('');
      return setIsAComment(false);
    }

    const sendMedia = async (mediaUrl) => {
      if (mediaUrl?.length > 0) {
        await sendMediaMessages(textMessage, currentChat, setTextMessage, mediaUrl);
      }
    };

    await Promise.all([
      sendMedia(urlImageToSend),
      sendMedia(urlVideoToSend),
      sendMedia(urlDocumentToSend),
    ]);

    const isAnyMediaPresent = urlImageToSend.length > 0
    || urlVideoToSend.length > 0
    || urlDocumentToSend.length > 0;

    if (!isAnyMediaPresent) {
      await sendMessage(
        textMessage,
        currentChat?.fromMsg,
        setTextMessage,
        audioBlobURL || null,
      );
    }

    setUrlImageToSend([]);
    setSelectedImageToSend(null);
    setUrlVideoToSend([]);
    setSelectedVideoToSend(null);
    setUrlDocumentToSend([]);
    setSelectedDocumentToSend(null);
    cancelAudioRecording();
  };

  const handleSendMessageWithKeyDown = async (event) => {
    const { key, shiftKey, target } = event;

    if (key === 'Enter' && !shiftKey) {
      handleSendMessage();
      return event.preventDefault();
    }

    if (key === 'Enter' && shiftKey) {
      event.preventDefault();
      const cursorPosition = target.selectionStart;
      const newText = `${textMessage.slice(0, cursorPosition)}\n${textMessage.slice(cursorPosition)}`;
      setTextMessage(newText);

      setTimeout(() => {
        target.selectionStart = cursorPosition + 1;
        target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const ipbxNumber = user?.senders[currentChat?.sender?.toString()]?.telefone?.toString();

  const contactName = currentChat?.customName
  || currentChat?.nome
  || formatPhoneNumber(currentChat?.fromMsg);

  if (!currentChat && !isNotDesktop) return <ChatIntro />;

  return currentChat && (
    <Container
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ContainerHeader>
        <ChatHeader
          contactName={contactName}
          isOnline={isRecipientOnline}
          ipbxNumber={formatPhoneNumber(ipbxNumber)}
        />
      </ContainerHeader>

      {urlImageToSend?.length > 0
        ? (
          <SendMedias
            type="image"
            urlMediaTosend={urlImageToSend}
            setMediaToSendFunc={setUrlImageToSend}
            setSelectedMediaToSendFunc={setSelectedImageToSend}
          />
        )
        : urlVideoToSend?.length > 0
          ? (
            <SendMedias
              type="video"
              urlMediaTosend={urlVideoToSend}
              setMediaToSendFunc={setUrlVideoToSend}
              setSelectedMediaToSendFunc={setSelectedVideoToSend}
            />
          )
          : urlDocumentToSend?.length > 0
            ? (
              <SendMedias
                type="document"
                urlMediaTosend={urlDocumentToSend}
                setMediaToSendFunc={setUrlDocumentToSend}
                setSelectedMediaToSendFunc={setSelectedDocumentToSend}
              />
            ) : (
              <ContainerChat
                ref={containerRef}
                id="containerChatId"
              >
                <InfiniteScroll
                  scrollableTarget="containerChatId"
                  dataLength={messages.length}
                  next={() => setMessagesPage((prevPage) => prevPage + 1)}
                  hasMore={hasMoreMessages}
                  loader={(
                    <div style={{
                      height: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    >
                      <CircularProgress variant="indeterminate" />
                    </div>
      )}
                  loaderStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  inverse
                  onScroll={handleScroll}
                  key={`${currentChat?.id}-${messages.length}`}
                >
                  {messages?.filter((message) => {
                    const isUserDeletedChat = (
                      message.firstId
                  === user.id
                  && message.firstIdDeleted
                    )
              || (
                message.secondId
                === user.id
                && message.secondIdDeleted
              );

                    return !isUserDeletedChat;
                  })
                    .reverse()
                    .map((message, index) => (
                      <div
                        key={index}
                        id={`message-${index}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                        ref={index === 0 ? setMessageToScroll : null}
                      >
                        <ChatMessage
                          body={message.body}
                          date={moment(message.createdAt).calendar()}
                          idMsg={message.idMsg}
                          senderId={message.sender}
                          mediaType={message.mediaType}
                          mediaUrl={message.mediaUrl}
                          videoThumb={message.videoThumb}
                          isDeleted={message.isDeleted}
                          isRecipientOnline={isRecipientOnline}
                          isReaded={message.ack >= 3}
                          isReceived={message.ack >= 2}
                          fromMe={message.fromMe}
                          isAComment={message.fromMe}
                        />
                      </div>
                    ))}

                </InfiniteScroll>
                <ScrollDownButton
                  style={{
                    bottom: height < 768 ? '25%' : '10%',
                  }}
                  className={messages.length !== 0 && !isChatFullyBottom
                    ? 'show'
                    : ''}
                  type="ScrollDownButton"
                  aria-label="Botão para descer o chat"
                  onClick={handleScrollChatToBottom}
                >
                  <ArrowDownwardIcon
                    style={{
                      color: '#919191',
                      fontSize: '40px',
                    }}
                  />
                </ScrollDownButton>
              </ContainerChat>

            )}

      <ContainerFooter ref={containerFooterRef}>
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          height="400px"
          searchPlaceHolder="Procurar"
          open={isEmojiMenuVisible}
          style={{
            position: 'absolute',
            bottom: '45px',
            zIndex: '1',
          }}
        />

        <div className="messages-input-container">
          <button
            aria-label="Adicionar comentário"
            type="button"
            style={{
              marginLeft: '15px',
              paddingTop: '5px',
            }}
            onClick={toggleIsAComment}
          >
            {!audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && (
              isAComment ? (
                <Tooltip title="Cancelar comentário">
                  <CloseIcon style={{
                    color: '#919191',
                    fontSize: '33px',
                  }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Adicionar comentário">
                  <TextsmsOutlinedIcon style={{
                    color: '#919191',
                    fontSize: '30px',
                  }}
                  />
                </Tooltip>
              )
            )}

          </button>
          {
            !audioBlobURL
            && !isRecordingAudio
            && !isUploadingAudioMessage
            && (currentChat.usuario_id === user.id
              || !currentChat.usuario_id
              || currentChat.status === 3
              || isAComment
            )

              ? (
                <>
                  <button
                    type="button"
                    className="emojis-button"
                    onClick={toggleEmojiMenu}
                  >
                    {isEmojiMenuVisible
                      ? (
                        <CloseIcon style={{ fontSize: '33px' }} />
                      )
                      : (
                        <InsertEmoticonIcon style={{ fontSize: '33px' }} />
                      )}
                  </button>
                  <textarea
                    ref={textareaRef}
                    placeholder={isAComment ? 'Digite um comentário' : 'Digite uma mensagem'}
                    value={textMessage}
                    onChange={(e) => {
                      handleChange(e);
                      handleResizeTextarea();
                    }}
                    onKeyDown={handleSendMessageWithKeyDown}
                    rows={1}
                    style={{
                      width: '100%',
                      resize: 'none',
                      overflowY: 'hidden',
                      lineHeight: '20px',
                      backgroundColor: isAComment ? '#FFFF66' : '#F5F5F5',
                    }}
                    onPaste={handlePaste}
                  />

                </>
              )
              : isRecordingAudio
              || audioBlobURL
              || isUploadingAudioMessage
                ? ''
                : (
                  <div className="no-input-container">
                    <h5>Chamado com outro atendente.</h5>
                    <button
                      type="button"
                      aria-label="Transferir chamado"
                      onClick={handleOpenTransferModal}
                    >
                      Transferir chamado
                    </button>
                  </div>
                )
        }

          {
            textMessage === ''
            && !selectedImageToSend
            && !selectedVideoToSend
            && !selectedDocumentToSend
            && (currentChat.usuario_id === user.id
              || !currentChat.usuario_id
              || currentChat.status === 3)
            && (
              <AudioRecorder />
            )
          }

          {(textMessage !== ''
          || audioBlobURL
          || selectedImageToSend
          || selectedVideoToSend
          || selectedDocumentToSend
          || isAComment)
        && (
          <button
            type="button"
            className="send-message-button"
            onClick={handleSendMessage}
            disabled={isMessagesLoading}
            aria-label="Ícone de enviar mensagem"
            style={{
              zIndex: '999',
            }}
          >
            <SendIcon style={{
              color: '#F5F5F5',
              fontSize: '25px',
              marginLeft: '3px',
              marginBottom: '2px',
            }}
            />
          </button>
        )}
        </div>
      </ContainerFooter>
    </Container>
  );
}
