import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin: 0 5px 10px 5px;
margin-bottom: 10px;
align-items: flex-start;

.message-content-div {
  border-bottom: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 20%;
  word-wrap: break-word;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  .message-menu-button {
    background-color: transparent;
    color: ${({ theme }) => theme.iconsColor};
    align-self: flex-end;
    margin-top: -5px;
    margin-bottom: -10px;
  }
  }

  .message-deleted-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 10px;
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.secondaryFontColor};
  }

  audio {
    max-width: 100%;
  }

  embed {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-message-div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .message-media-div {
    padding: 5px;
    max-width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 500px;
      height: 200px;
      border-radius: 4px;

      svg {
        color: ${({ theme }) => theme.secondaryColor};
        position: absolute;
        background-color: ${({ theme }) => theme.inputBackground};
        border-radius: 6px;
        bottom: 36%;
        right: 38%;
        font-size: 60px;
      }
    }
  }

  .time-and-confirmation-span {
    margin-top: 5px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 20px 60px;
  border: 1px solid #AEBAC1;
  border-radius: 12px;
  background: linear-gradient(135deg, #F5F7FA, #E4EBF1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.2;
  }

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.blueTelein};
    padding: 5px 12px;
    border-radius: 5px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  span {
    margin-bottom: 5px;
  }
`;
